// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$arrow-2-name: 'arrow-2';
$arrow-2-x: 0px;
$arrow-2-y: 0px;
$arrow-2-offset-x: 0px;
$arrow-2-offset-y: 0px;
$arrow-2-width: 150px;
$arrow-2-height: 103px;
$arrow-2-total-width: 463px;
$arrow-2-total-height: 359px;
$arrow-2-image: '../img/sprite.png';
$arrow-2: (0px, 0px, 0px, 0px, 150px, 103px, 463px, 359px, '../img/sprite.png', 'arrow-2', );
$arrow-3-name: 'arrow-3';
$arrow-3-x: 118px;
$arrow-3-y: 303px;
$arrow-3-offset-x: -118px;
$arrow-3-offset-y: -303px;
$arrow-3-width: 90px;
$arrow-3-height: 56px;
$arrow-3-total-width: 463px;
$arrow-3-total-height: 359px;
$arrow-3-image: '../img/sprite.png';
$arrow-3: (118px, 303px, -118px, -303px, 90px, 56px, 463px, 359px, '../img/sprite.png', 'arrow-3', );
$arrow-4-name: 'arrow-4';
$arrow-4-x: 0px;
$arrow-4-y: 303px;
$arrow-4-offset-x: 0px;
$arrow-4-offset-y: -303px;
$arrow-4-width: 118px;
$arrow-4-height: 56px;
$arrow-4-total-width: 463px;
$arrow-4-total-height: 359px;
$arrow-4-image: '../img/sprite.png';
$arrow-4: (0px, 303px, 0px, -303px, 118px, 56px, 463px, 359px, '../img/sprite.png', 'arrow-4', );
$arrow-5-name: 'arrow-5';
$arrow-5-x: 375px;
$arrow-5-y: 0px;
$arrow-5-offset-x: -375px;
$arrow-5-offset-y: 0px;
$arrow-5-width: 88px;
$arrow-5-height: 70px;
$arrow-5-total-width: 463px;
$arrow-5-total-height: 359px;
$arrow-5-image: '../img/sprite.png';
$arrow-5: (375px, 0px, -375px, 0px, 88px, 70px, 463px, 359px, '../img/sprite.png', 'arrow-5', );
$arrow-name: 'arrow';
$arrow-x: 150px;
$arrow-y: 0px;
$arrow-offset-x: -150px;
$arrow-offset-y: 0px;
$arrow-width: 225px;
$arrow-height: 68px;
$arrow-total-width: 463px;
$arrow-total-height: 359px;
$arrow-image: '../img/sprite.png';
$arrow: (150px, 0px, -150px, 0px, 225px, 68px, 463px, 359px, '../img/sprite.png', 'arrow', );
$board-name: 'board';
$board-x: 0px;
$board-y: 203px;
$board-offset-x: 0px;
$board-offset-y: -203px;
$board-width: 100px;
$board-height: 100px;
$board-total-width: 463px;
$board-total-height: 359px;
$board-image: '../img/sprite.png';
$board: (0px, 203px, 0px, -203px, 100px, 100px, 463px, 359px, '../img/sprite.png', 'board', );
$case-name: 'case';
$case-x: 200px;
$case-y: 203px;
$case-offset-x: -200px;
$case-offset-y: -203px;
$case-width: 100px;
$case-height: 100px;
$case-total-width: 463px;
$case-total-height: 359px;
$case-image: '../img/sprite.png';
$case: (200px, 203px, -200px, -203px, 100px, 100px, 463px, 359px, '../img/sprite.png', 'case', );
$check-small-name: 'check-small';
$check-small-x: 417px;
$check-small-y: 70px;
$check-small-offset-x: -417px;
$check-small-offset-y: -70px;
$check-small-width: 13px;
$check-small-height: 10px;
$check-small-total-width: 463px;
$check-small-total-height: 359px;
$check-small-image: '../img/sprite.png';
$check-small: (417px, 70px, -417px, -70px, 13px, 10px, 463px, 359px, '../img/sprite.png', 'check-small', );
$check-name: 'check';
$check-x: 375px;
$check-y: 70px;
$check-offset-x: -375px;
$check-offset-y: -70px;
$check-width: 42px;
$check-height: 42px;
$check-total-width: 463px;
$check-total-height: 359px;
$check-image: '../img/sprite.png';
$check: (375px, 70px, -375px, -70px, 42px, 42px, 463px, 359px, '../img/sprite.png', 'check', );
$client-name: 'client';
$client-x: 100px;
$client-y: 203px;
$client-offset-x: -100px;
$client-offset-y: -203px;
$client-width: 100px;
$client-height: 100px;
$client-total-width: 463px;
$client-total-height: 359px;
$client-image: '../img/sprite.png';
$client: (100px, 203px, -100px, -203px, 100px, 100px, 463px, 359px, '../img/sprite.png', 'client', );
$design-name: 'design';
$design-x: 100px;
$design-y: 103px;
$design-offset-x: -100px;
$design-offset-y: -103px;
$design-width: 100px;
$design-height: 100px;
$design-total-width: 463px;
$design-total-height: 359px;
$design-image: '../img/sprite.png';
$design: (100px, 103px, -100px, -103px, 100px, 100px, 463px, 359px, '../img/sprite.png', 'design', );
$location-name: 'location';
$location-x: 0px;
$location-y: 103px;
$location-offset-x: 0px;
$location-offset-y: -103px;
$location-width: 100px;
$location-height: 100px;
$location-total-width: 463px;
$location-total-height: 359px;
$location-image: '../img/sprite.png';
$location: (0px, 103px, 0px, -103px, 100px, 100px, 463px, 359px, '../img/sprite.png', 'location', );
$print-name: 'print';
$print-x: 200px;
$print-y: 103px;
$print-offset-x: -200px;
$print-offset-y: -103px;
$print-width: 100px;
$print-height: 100px;
$print-total-width: 463px;
$print-total-height: 359px;
$print-image: '../img/sprite.png';
$print: (200px, 103px, -200px, -103px, 100px, 100px, 463px, 359px, '../img/sprite.png', 'print', );
$spritesheet-width: 463px;
$spritesheet-height: 359px;
$spritesheet-image: '../img/sprite.png';
$spritesheet-sprites: ($arrow-2, $arrow-3, $arrow-4, $arrow-5, $arrow, $board, $case, $check-small, $check, $client, $design, $location, $print, );
$spritesheet: (463px, 359px, '../img/sprite.png', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
