@import "../../node_modules/bootstrap/scss/functions";
@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/wowjs/css/libs/animate";

@import 'css/sprite.scss';
/*

@import '../../node_modules/sass-mq/_mq';

$mq-breakpoints: (
  mobile:  320px,
  mobileX: 480px,
  tablet:  768px,
  desktop: 1024px,
  wide:    1366px
);
*/

@font-face {
  font-family: 'Ubuntu';
  src: url('../fonts/Ubuntu-Regular.eot');
  src: local('☺'),
  url('../fonts/Ubuntu-Regular.woff') format('woff'),
  url('../fonts/Ubuntu-Regular.ttf') format('truetype'),
  url('../fonts/Ubuntu-Regular.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Ubuntu';
  src: url('../fonts/Ubuntu-Bold.eot');
  src: local('☺'),
  url('../fonts/Ubuntu-Bold.woff') format('woff'),
  url('../fonts/Ubuntu-Bold.ttf') format('truetype'),
  url('../fonts/Ubuntu-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'IBMPlexSerif';
  src: url('../fonts/IBMPlexSerif-Bold.eot');
  src: local('☺'),
  url('../fonts/IBMPlexSerif-Bold.woff') format('woff'),
  url('../fonts/IBMPlexSerif-Bold.ttf') format('truetype'),
  url('../fonts/IBMPlexSerif-Bold.svg') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Calibri';
  src: url('../fonts/calibri.eot');
  src: local('☺'),
  url('../fonts/calibri.woff') format('woff'),
  url('../fonts/calibri.ttf') format('truetype'),
  url('../fonts/calibri.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Calibri';
  src: url('../fonts/calibrib.eot');
  src: local('☺'),
  url('../fonts/calibrib.woff') format('woff'),
  url('../fonts/calibrib.ttf') format('truetype'),
  url('../fonts/calibrib.svg') format('svg');
  font-weight: bold;
  font-style: normal;
}


body {
  font-family: "Ubuntu", sans-serif;
  letter-spacing: .04em;
  @include media-breakpoint-down(lg) {
    font-size: 14px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 13px;
  }
}
.container {
  max-width: 1230px;
}
.burger {
  display: none;
  width: 38px;
  flex-basis: 38px;
  flex-grow: 0;
  flex-shrink: 0;
  //position: relative;
  vertical-align: middle;
  transition: .5s ease;
  padding-top: 2px;
  padding-bottom: 2px;
  position: absolute;
  right: 15px;
  top: 50px;
  z-index: 99;
  span {
    height: 4px;
    background: #333;
    border-radius: 2px;
    display: block;
  }
  &:before,&:after {
    content: '';
    display: block;
    background: #333;
    height: 4px;
    border-radius: 2px;
    position: absolute;
    top: -10px;
    width: 100%;
    left: 0;
    transition: .2s ease;
  }
  &:after {
    top: auto;
    bottom: -10px;
  }
  &.active {
    background: transparent;
    &:before {
      transform: rotate(135deg);
      top: 4px;
      background: #FFF;
    }
    &:after {
      transform: rotate(-135deg);
      bottom: 0;
      background: #FFF;
    }
    span {
      background: none;
    }
  }
  @include media-breakpoint-down(xs) {
    display: inline-block;
  }
}
.logo {
  display: inline-block;
  width: 0;
  height: 0;
  padding: 71px 240px 0 0;
  font-size: 0;
  background: url("../img/logo.png") 50% 50% no-repeat;
  background-size: contain;
  @include media-breakpoint-down(xs) {
    padding: 52px 176px 0 0;
  }
}
.input,input.input {
  border: 1px solid #ddd;
  border-radius: 3px;
  font-size: 14px;
  padding: 5px 10px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1) inset;
}
.header {
  padding-top: 22px;
  padding-bottom: 10px;
  .cell {
    &-text {
      line-height: 25px;
      font-size: 18px;
      font-weight: bold;
    }
  }
  a[href*="tel:"] {
    font-size: 33px;
    font-family: 'IBMPlexSerif', sans-serif;
    color: #e7462e;
    text-decoration: none;
    font-weight: bold;
    letter-spacing: .04em;
    white-space: nowrap;

  }
  @include media-breakpoint-down(lg) {
    a[href*="tel:"] {
      font-size: 24px;
    }
    .cell {
      &-text {
        font-size: 16px;
        line-height: 1.3;
      }
    }
  }
  @include media-breakpoint-down(md) {
    .cell {
      &-text {
        font-size: 14px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    position: relative;
    padding-top: 10px;
    a[href*="tel:"] {
      font-size: 23px;
    }
  }
}
.auth-links {
  font-family: Calibri, sans-serif;

  a {
    color: #000;
    text-decoration: underline;
    margin-left: 20px;
    &:hover {
      text-decoration: none;
    }
  }
}
.menu {
  background: #f05b3d;
  font: bold 18px Calibri;
  a {
    color: #FFF;
    display: inline-block;
    padding: 9px 10px;
  }
  @include media-breakpoint-down(xs) {
    height: 12px;
    margin-top: -3px;
    .container {
      position: fixed;
      z-index: 98;
      top: 0;
      left: 0;
      bottom: 0;
      overflow-y: auto;
      padding-right: 60px;
      padding-top: 40px;
      background: rgba(#f05b3d,.95);
      transform: translateX(-100%);
      transition: .3s ease;
      &.active {
        transform: translateX(0);
      }
    }
    a {
      display: block;
    }
  }
}
.section-title {
  font-size: 30px;
  text-transform: uppercase;
  font-weight: bold;
  @include media-breakpoint-down(lg) {
    font-size: 24px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }
}
.section-description {
  line-height: 25px;
  @include media-breakpoint-down(sm) {
    line-height: 20px;
  }
}
.title {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: bold;
  @include media-breakpoint-down(lg) {
    font-size: 16px;
  }
}

.promo {
  padding-top: 23px;
  padding-bottom: 64px;
  .section-title {
    font-size: 35px;
    line-height: 45px;
    margin-bottom: 16px;
  }
  .title {
    margin-bottom: 14px;
  }
  &__image {
    position: relative;
    z-index: -1;
    &:after {
      content: '';
      display: block;
      padding-bottom: 120%;
    }
    &:before {
      content: '';
      display: block;
      top: 0;
      bottom: 0;
      right: 0;
      width: 150%;
      background: url("../img/image-01.jpg") 100% 50% no-repeat;
      background-size: contain;
      position: absolute;
    }
  }
  .section-description {
    width: 400px;
    max-width: 100%;
    margin-bottom: 30px;
    position: relative;
    &:after {
      content: '';
      display: none;
      @include sprite($arrow);
      position: absolute;
      left: 85%;
    }
  }
  @include media-breakpoint-down(lg) {
    .section-title {
      font-size: 26px;
      line-height: 1.3;
    }
    .section-description {
      &:after {
        transform: scale(.6);
      }
    }
  }
  @include media-breakpoint-down(md) {
    .section-description {
      width: auto;
    }
  }
  @include media-breakpoint-up(sm) {
    .section-description {

      &:after {
        display: block;
      }
    }
  }
  @include media-breakpoint-down(xs) {
    padding-top: 40px;
    .title {
      display: none;
    }
    .section-title {
      font-size: 20px;
      line-height: 30px;
      text-align: left;
      padding-bottom: 130px;
      position: relative;
      margin-bottom: 11px;
      &:after {
        content: '';
        display: block;
        background: #000;
        background: url(../img/image-01.jpg) 100% 50% no-repeat;
        background-size: contain;
        width: 160px;
        height: 160px;
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
      }
      &:before {
        @include sprite($arrow);
        content: '';
        display: block;
        position: absolute;
        right: 35%;
        bottom: 40px;
        transform: scale(.5) rotate(20deg);
      }
    }
    .section-description {
      margin-bottom: 24px;
    }
  }
}
.btn {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
  border-radius: 24px;
  padding-left: 40px;
  padding-right: 40px;
  letter-spacing: .04em;
  &:hover {
    color: #FFF;
  }
  &-red {
    background: #d90101;
    color: #FFF;
    &:hover {
      background: #c00101;
    }
  }
  &-white {
    background: #FFF;
    color: #000;
    text-transform: none;
    font-size: 16px;
    padding:12px 20px;
    box-shadow: 0 10px 40px rgba(#000, .6);
    &:hover {
      color: #222;
      background: #ebebeb;
      box-shadow: 0 5px 20px rgba(#000, .7);
    }
  }
}
.advantages {
  background: url("../img/image-06.jpg") 50% 50% no-repeat;
  background-size: cover;
  position: relative;
  color: #FFF;
  padding: 65px 0 71px;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000,.65);
    z-index: 0;
  }
  .container {
    position: relative;
    z-index: 1;
  }
  .section-title {
    margin-bottom: 42px;
  }
  &__item {
    width: 300px;
    max-width: 100%;
    margin: 0 auto;
    text-transform: uppercase;
    line-height: 25px;
    &:before {
      content: '';
      display: block;
      width: 100px;
      height: 100px;
      background: #FFF;
      border-radius: 100%;
      margin: 0 auto 26px;
    }
    &-location {
      &:before {
        @include sprite-image($location);
        @include sprite-position($location);
      }
    }
    &-client {
      &:before {
        @include sprite-image($client);
        @include sprite-position($client);
      }
    }
    &-case {
      &:before {
        @include sprite-image($case);
        @include sprite-position($case);
      }
    }
  }
  @include media-breakpoint-down(sm) {
    &__item {
      font-size: 12px;
      line-height: 1.3;
      margin-bottom: 30px;
      &:before {
        //transform: scale(.6);
        margin-bottom: 20px;
      }
    }
  }
  @include media-breakpoint-down(xs) {
    padding-top: 57px;
    padding-bottom: 54px;
  }
}
.about {
  background: #e3e3e3;
  padding-top: 80px;
  padding-bottom: 87px;
  .section-description {
    line-height: 30px;
    &:not(:last-of-type) {
      margin-bottom: 32px;
    }
  }
  .title {
    margin-bottom: 20px;
    &-arrow {
      position: relative;
      &:before {
        content: '';
        position: absolute;
        right: 100%;
        top: 20px;
        margin-right: 40px;
        display: block;
        @include sprite($arrow-2);
      }
    }
  }
  .section-title {
    margin-bottom: 45px;
  }
  @include media-breakpoint-down(xs) {
    padding-bottom: 61px;
  }
}
.services {
  padding-top: 64px;
  padding-bottom: 49px;
  &__list {
    padding:0;
    margin: 0;
    list-style: none;
    li {
      text-transform: uppercase;
      position: relative;
      padding-left: 64px;
      letter-spacing: .08em;
      &:not(:last-child) {
        margin-bottom: 51px;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        @include sprite($check);
        left: 0;
        top: -10px;
      }
    }
  }
  .section-title {
    margin-bottom: 47px;
    //margin-top: 26px;
  }
}
.placement {
  background: #e3e3e3;
  padding-top: 87px;
  padding-bottom: 100px;
  .section-title {
    margin-bottom: 12px;
  }
  .section-description {
    width: 570px;
    margin: 0 auto 41px;
    max-width: 100%;
    line-height: 21px;
  }
  &__item {
    width: 360px;
    flex-basis: 360px;
    margin: 0 15px;
    max-width: 100%;
    display: inline-flex;
    flex-direction: column;
    border-radius: 10px;
    overflow: hidden;
    &-image {
      background: 50% 50% no-repeat;
      background-size: cover;
      img {
        display: none;
      }
      &:before {
        content: '';
        display: block;
        padding-bottom: 56.94444%;
      }
    }
    &-title {
      height: 90px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFF;
      font-weight: bold;
      font-size: 20px;
      text-transform: uppercase;
    }
  }
  &__list {
    display: flex;
    justify-content: center;
  }
  @include media-breakpoint-down(md) {
    &__item {
      margin: 0 5px;
      &-title {
        font-size: 14px;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    &__list {
      flex-direction: column;
    }
    &__item {
      flex-basis: auto;
      margin-bottom: 30px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @include media-breakpoint-down(xs) {
    padding-top: 43px;
    padding-bottom: 20px;
  }
}
.ready {
  padding-top: 93px;
  padding-bottom: 84px;
  .section-description {
    line-height: 30px;
    font-size: 18px;
    width: 600px;
    max-width: 100%;
    margin: 0 auto 37px;
  }
  .section-title {
    margin-bottom: 9px;
  }
  .title {
    margin-bottom: 16px;
  }
  @include media-breakpoint-down(xs) {
    padding-top: 43px;
    padding-bottom: 40px;
  }
}
.demands {
  &__list {
    padding: 0 0 0 40px;
    margin: 0;
    list-style: none;
    width: 600px;
    max-width: 100%;
    li {
      line-height: 30px;
      position: relative;
      &:before {
        content: '';
        position: absolute;
        left: -40px;
        top: 10px;
        @include sprite($check-small);
      }
    }
  }
  &__image {
    text-align: right;
    padding-top: 9px;
    img {
      margin-right: 20px;
    }
  }
}
.price {
  padding-top: 58px;
  padding-bottom: 64px;
  background: url("../img/image-07.jpg") 50% 50% no-repeat;
  background-size: cover;
  position: relative;
  color: #FFF;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(#000,.7);
    z-index: 0;
  }
  .container {
    position: relative;
    z-index: 1;
  }
  &__list {
    display: flex;
    justify-content: space-between;
  }
  &__item {
    flex-basis: 300px;
    flex-shrink: 0;
    flex-grow: 0;
    border:1px solid #FFF;
    border-radius: 10px;
    text-transform: uppercase;
    padding: 44px 10px 32px;
    &:before {
      content: '';
      display: block;
      margin: 0 auto 17px;
    }
    &:nth-of-type(1) {
      margin-right: 20px;
      &:before {
        @include sprite($board);
      }
    }
    &:nth-of-type(2) {
      &:before {
        @include sprite($design);
      }
    }
    &:nth-of-type(3) {
      margin-left: 20px;
      &:before {
        @include sprite($print);
      }
    }
  }
  &__title {
    font-size: 20px;
    font-weight: bold;
  }
  del {
    font-weight: normal;
    text-transform: none;
  }
  .section-title {
    margin-bottom:42px
  }
  @include media-breakpoint-down(lg) {
    &__item {
      flex-basis: calc(33.33% - 10px);
    }
  }
  @include media-breakpoint-down(md) {
    &__title {
      font-size: 16px;
    }
  }
  @include media-breakpoint-down(sm) {
    &__list {
      flex-direction: column;
    }
    &__item {
      flex-basis: auto;
      width: 260px;
      margin: 0 auto 30px!important;
    }
  }
  @include media-breakpoint-down(xs) {
    padding-top: 43px;
    padding-bottom: 20px;
  }
}
.how {
  background: #dadada;
  padding-top: 83px;
  padding-bottom: 84px;
  &__list {
    font-size: 16px;
    line-height: 30px;
    font-weight: bold;
    display: block;
    max-width: calc(100% - 30px);
    padding: 0;
    margin: 0 auto;
    counter-reset:count;
    position: relative;
    &:before {
      content: '';
      display: block;
      width: 718px;
      max-width: 100%;
      padding-bottom: 93.0362%;
      background: url("../img/image-05.jpg") 50% 50% no-repeat;
      background-size: contain;
      margin: 0 auto;
    }
    li {
      position: absolute;
      display: inline-block;
      text-align: left;
      &:before {
        counter-increment: count;
        content: counter(count);
        color: #FFF;
        background: #c40101;
        display: flex;
        width: 52px;
        height: 52px;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        font-size: 30px;
        position: absolute;
        left: -70px;
        top: 6px;
      }
      &:nth-child(1) {
        width: 160px;
        top: 25%;
        right: 100%;
        &:after {
          content: '';
          display: block;
          @include sprite($arrow-5);
          position: absolute;
          left: 60px;
          bottom: 100%;
          margin: 13px 0 0;
        }
      }
      &:nth-child(2) {
        width: 250px;
        bottom: 13%;
        left: -22%;
        &:after {
          content: '';
          display: block;
          @include sprite($arrow-4);
          position: absolute;
          left: 100%;
          top: 100%;
          margin: 13px 0 0 -40px;
        }
      }
      &:nth-child(3) {
        width: 155px;
        top: 27.5%;
        left: 100%;
        margin-left: 11%;
        &:before {
          margin-top: -17px;
        }
        &:after {
          content: '';
          display: block;
          @include sprite($arrow-3);
          position: absolute;
          right: 100%;
          bottom: 100%;
          margin: 13px 0 0 -40px;
        }
      }
      &:nth-child(4) {
        width: 200px;
        bottom: -2%;
        left: 100%;
        margin-left: 4%;
        &:before {
          position: static;
          margin-bottom: 15px;
        }
      }
    }
  }
  .section-description {
    margin-bottom: 32px
  }
  @include media-breakpoint-down(lg) {
    &__list {
      font-size: 14px;
      line-height: 1.3;
      li {
        padding: 10px 10px 10px 50px ;
        background: rgba(#fff,.8);
        border:1px solid #333;
        width: 250px!important;
        &:before {
          width: 30px;
          height: 30px;
          font-size: 18px;
          left: 10px;
          top: 10px;
        }

        &:nth-child(1) {
          right: auto;
          left: 5%;
          top: 20%;
          &:after {
            content: '';
            display: block;
            @include sprite($arrow-3);
            position: absolute;
            left: 0;
            bottom: 100%;
            transform: scale(.5);
          }
        }
        &:nth-child(2) {
          left: 2%;
          &:after {
            transform: scale(.5);
            margin: 20px -40px 0 0;
            left: auto;
            right: 50%;
          }
        }
        &:nth-child(3) {
          top: 27.5%;
          left: auto;
          right: 5%;
          margin-left: 0;
          &:before {
            margin-top: -8px;
          }
          &:after {
            transform: scale(-0.5, .5);
            right: 20%;
          }
        }
        &:nth-child(4) {
          left: auto;
          right: 2%;
          &:before {
            position: absolute;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(sm) {
    &__list {
      &:before {
        margin-bottom: 30px;
      }
      li {
        position: relative;
        width: auto !important;
        left: auto!important;
        top: auto!important;
        right: auto!important;
        bottom: auto!important;
        margin:0 0 20px!important;
        padding: 5px !important;
        display: flex;
        align-items: center;
        background: none;
        border: none;
        &:after {
          display: none !important;
        }
        &:before {
          position: static!important;
          margin: 0 10px 0 0 !important;
          flex-basis: 30px;
          flex-shrink: 0;
          flex-grow: 0;
        }
      }
    }
  }
  @include media-breakpoint-down(xs) {
    padding-top: 43px;
    padding-bottom: 21px;
  }
}
.select {
  background: url("../img/image-07.jpg") 50% 50% no-repeat;
  background-size: cover;
  position: relative;
  color: #FFF;
  border-bottom: 8px solid #e7462e;
  padding-top: 51px;
  padding-bottom: 38px;
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(#810000,.9);
    z-index: 0;
  }
  .container {
    position: relative;
    z-index: 1;
  }
  .section-title {
    margin-bottom: 19px;
  }
  @include media-breakpoint-down(xs) {
    padding-top: 43px;
    padding-bottom: 42px;
  }
}
.footer {
  padding-top: 30px;
  padding-bottom: 30px;
  .copyright {
    font-size: 11px;
    color: #939393;
    margin-left: 20px;
    margin-top: 10px;
  }
  .copy-link {
    display: inline-block;
  }
  .cell {
    &-contacts {
      font-weight: bold;
      a {
        text-decoration: none;
        color: #000;
      }
      a[href*="tel"] {
        font: bold 30px 'IBMPlexSerif';
        color: #e7462e;
        white-space: nowrap;
      }
      .tel {
        margin: 10px 0;
      }
    }
    &-platform {
      font-size: 11px;
      a {
        text-decoration: underline;
        color: #000;
        &:after {
          content: '';
          display: inline-block;
          float: right;
          width: 0;
          height: 0;
          padding: 32px 70px 0 0;
          background: url("../img/advvg.jpg") 50% 50% no-repeat;
          background-size: cover;
          margin: 0 0 0 20px;
        }
        &:hover {
          text-decoration: none;
        }
      }
      .copy-link {
        //clear: right;
        color: #939393;
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .cell-platform {
      a {
        display: inline-flex;
        align-items: center;
      }
    }
  }
  @include media-breakpoint-down(sm) {
    .cell {
      &-contacts {
        margin-bottom: 30px;
      }
    }
    .copyright {
      margin: 10px 0 30px;
    }
  }
  @include media-breakpoint-down(md) {
    .cell {
      &-platform {
        margin-top: 30px;
      }
    }
    .copy-link {
      display: block;
    }
  }
  @include media-breakpoint-down(xs) {
    padding-top: 50px;
  }
}

.popup {
  background: #FFF;
  display: none;
  width: 320px;
  max-width: 100%;
  margin: auto;
  box-shadow: 0 0 50px rgba(#000, .7);
  .fade & {
    display: block;
  }
  &-title {
    font-size: 18px;
    padding: 10px 20px;
    border-bottom: 1px solid #ddd;
    .dotted {
      color: #4a86e8;
      cursor: pointer;
      border-bottom: 1px dotted #4a86e8;
      transition: .3s ease;
      &:hover {
        color: #2e71e8;
      }
    }
  }
  &-body {
    padding: 20px;
    font-size: 18px;
    text-align: center;
  }

  .submit {
    background: #1fb12d;
    &:hover {
      background: #1ea329;
    }
  }
  .input {
    display: block;
    width: 100%;
    margin-bottom: 10px;
    box-sizing: border-box;
    font-size: 13px;
    padding: 5px 10px;
  }
  .text-center {
    text-align: center;
    margin-top: 10px;
  }
  &-close {
    float: right;
    font-size: 24px;
    font-weight: bold;
    color: #444;
    display: block;
    text-decoration: none;
    padding: 3px 10px;
    line-height: 1;
    border-radius: 3px;
    &:hover {
      background: rgba(#000,.1);
      transition: .3s ease;
      text-decoration: none;
      color: #444;
    }
  }
  .auth-box {
    display: none;
    &.active {
      display: block;
    }
  }
  .remind-link {
    text-align: right;
    font-size: 13px;
    text-decoration: none;
    border-bottom: 1px dotted;
    &__holder {
      margin-bottom: 20px;
    }
  }
  .nice-select {
    display: block;
    width: 100%;
    margin-bottom: 20px;
  }
  &-notice {
    font-size: 12px;
    font-style: italic;
    line-height: 1.2;
    margin: 5px 0;
    color: #555;
  }
  hr {
    height: 1px;
    border: none;
    background: #efefef;
    margin: 1em 0;
  }
  .easy-autocomplete {
    width: 100%;
    text-align: left;
  }
}
.fade {
  display: none;
  position: fixed;
  align-items: center;
  justify-content: center;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(#000,.75);
  z-index: 99;
  padding: 10px;
  overflow: auto;
  &.show {
    display: flex;
  }
}
.up {
  display: flex;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  background: #efefef;
  border-radius: 100%;
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 99;
  opacity: 0;
  transition: .3s ease;
  pointer-events: none;
  cursor: pointer;
  &:before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border-top:2px solid #333;
    border-right:2px solid #333;
    transform: rotate(-45deg);
    margin-bottom: -5px;
  }
  &:hover {
    background: #ccc;
  }
  &.active {
    opacity: 1;
    pointer-events: auto;
  }
}